document.addEventListener('DOMContentLoaded', function(event) {

    var search = document.getElementById('search'),
        searchSubmit = document.getElementById('searchSubmit'),
        searchResultsWrapper = document.getElementById('searchResultsWrapper'),
        searchResultsCount = document.getElementById('searchResultsCount'),
        searchResults = document.getElementById('searchResults');

    function searchAction(term) {

            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {

                if( this.responseText ) {
                    var response = JSON.parse(this.responseText);

                    searchResultsWrapper.classList.remove('d-none');
                    searchResultsWrapper.classList.add('d-block');

                    if( response.success ) {
                        // console.log('response.success');
                        // console.log(response.success);
                        if( response.success.path == 'victor' ) {
                            searchResults.innerHTML = '<span class="link cursor-pointer" onclick="passwordOpen()">' + response.success.text + '</span>';
                        } else {
                            searchResults.innerHTML = '<a href="' + response.success.path + '" target="_blank">' + response.success.text + '</a>';
                        }

                        searchResultsCount.innerHTML = '1 Match';
                        searchResults.classList.remove('d-none');
                        searchResults.classList.add('d-block');
                    }

                    if( response.error ) {
                        // console.log('response.error');
                        // console.log(response.error);

                        searchResultsCount.innerHTML = '0 Matches';

                        searchResults.classList.add('d-none');
                        searchResults.classList.remove('d-block');
                    }
                }
            };
            xhttp.open("GET", "inc/search.php?search="+term, true);
            xhttp.send();
    }

    searchSubmit.addEventListener('click', function(event){
        searchAction(search.value);
    });


    search.addEventListener("keyup", event => {

        if (event.keyCode === 13) {
            searchSubmit.click();
        }
    });
});
