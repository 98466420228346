var passwordLightbox = document.getElementById('passwordLightbox'),
    passwordInstructions = document.getElementById('passwordInstructions'),
    password = document.getElementById('password'),
    passwordSuccess = document.getElementById('passwordSuccess'),
    passwordError = document.getElementById('passwordError'),
    passwordSubmit = document.getElementById('passwordSubmit')
    passwordLevel = 1,
    passwordSafe = true,
    account = document.getElementById('account');

function passwordOpen(){
    password.value = '';

    passwordLightbox.classList.add('d-flex');
    passwordLightbox.classList.remove('d-none');

    passwordSuccess.classList.remove('d-block');
    passwordSuccess.classList.add('d-none');

    passwordError.classList.remove('d-block');
    passwordError.classList.add('d-none');

    password.focus();
}

document.addEventListener('DOMContentLoaded', function() {

    passwordSubmit.addEventListener('click', function() {
        var passwordText = password.value;
        console.log(passwordText);
        passwordText = passwordText.replace('#', 'x');
        console.log(passwordText);

        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {

            if( this.responseText ) {
                var response = JSON.parse(this.responseText);

                if( response.success ) {
                    console.log('response.success');
                    console.log(response.success);

                    passwordSuccess.classList.add('d-block');
                    passwordSuccess.classList.remove('d-none');

                    passwordError.classList.remove('d-block');
                    passwordError.classList.add('d-none');

                    passwordSuccess.innerHTML = 'Credentials have been verified.';

                    if( passwordLevel == 1 ) {
                        if( passwordSafe ) {
                            passwordSafe = false;

                            setTimeout(function(){
                                passwordLightbox.classList.add('d-none');
                                passwordLightbox.classList.remove('d-flex');
                                account.classList.remove('d-none');
                                account.classList.add('d-flex');
                            }, 2000);

                            setTimeout(function(){
                                passwordInstructions.innerHTML = '*** CLASSIFIED DOCUMENT ***<br />INPUT BPI CLEARANCE CODE:';
                                openPopup();
                                passwordLevel++;
                                passwordSafe = true;
                            }, 5000);
                        }
                    } else {
                        var searchResults = document.getElementById('searchResults');
                        searchResults.innerHTML = '<a href="' + response.success.path + '" target="_blank">' + response.success.text + '</a>';

                        if( passwordSafe ) {
                            passwordSafe = false;

                            var path = response.success.path;

                            setTimeout(function(){
                                console.log('path');
                                console.log(path);
                                window.open(path, '_blank').focus();

                                passwordLightbox.classList.add('d-none');
                                passwordLightbox.classList.remove('d-flex');
                                passwordSafe = true;
                            }, 2000);
                        }
                    }
                }

                if( response.error ) {
                    console.log('response.error');
                    console.log(response.error);

                    var errorText = '<ul>';

                    passwordSuccess.classList.remove('d-block');
                    passwordSuccess.classList.add('d-none');

                    passwordError.classList.add('d-block');
                    passwordError.classList.remove('d-none');

                    for( var i = 0; i < response.error.length; i++ ){
                        errorText += '<li>' + response.error[i] + '</li>';
                    }

                    errorText += '</ul>';

                    passwordError.innerHTML = errorText;
                }
            }
        };
        xhttp.open("GET", "inc/password.php?password="+passwordText+'&level='+passwordLevel, true);
        xhttp.send();
    });


    password.addEventListener("keyup", event => {

        if (event.keyCode === 13) {
            passwordSubmit.click();
        }
    });

    password.focus();

    if( document.cookie ) {
        var c = document.cookie + '; test=foobar';
            console.log(c);

        var start = c.indexOf('bpi-pass=');

        c = c.substring(start);
            console.log(c);

        if( c.indexOf(';') >= 0 ) {
            var end = c.indexOf(';');
            c = c.substring(0, end);
        }

        c = c.replace('bpi-pass=', '');

        console.log(c);

        password.value = c;
        passwordSubmit.click();
    }
});
