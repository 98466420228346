document.addEventListener('DOMContentLoaded', function(event) {

    var recentLink1 = document.getElementById('recentLink1'),
        recentLink2 = document.getElementById('recentLink2'),
        recentIndex = document.getElementById('recentIndex'),
        recentDoc1 = document.getElementById('recentDoc1'),
        recentDoc2 = document.getElementById('recentDoc2'),
        searchResultsWrapper = document.getElementById('searchResultsWrapper');

    function openRecentIndex(){
        recentIndex.classList.remove('d-none');
        recentIndex.classList.add('d-flex');

        recentDoc1.classList.add('d-none');
        recentDoc1.classList.remove('d-flex');

        recentDoc2.classList.add('d-none');
        recentDoc2.classList.remove('d-flex');

        searchResultsWrapper.classList.add('d-none');
        searchResultsWrapper.classList.remove('d-block');
    }

    function openRecentDoc1(){
        recentIndex.classList.add('d-none');
        recentIndex.classList.remove('d-flex');

        recentDoc1.classList.remove('d-none');
        recentDoc1.classList.add('d-flex');

        recentDoc2.classList.add('d-none');
        recentDoc2.classList.remove('d-flex');

        searchResultsWrapper.classList.add('d-none');
        searchResultsWrapper.classList.remove('d-block');
    }

    function openRecentDoc2(){
        recentIndex.classList.add('d-none');
        recentIndex.classList.remove('d-flex');

        recentDoc1.classList.add('d-none');
        recentDoc1.classList.remove('d-flex');

        recentDoc2.classList.remove('d-none');
        recentDoc2.classList.add('d-flex');

        searchResultsWrapper.classList.add('d-none');
        searchResultsWrapper.classList.remove('d-block');
    }

    recentLink1.addEventListener('click', function(event){
        event.preventDefault();
        openRecentDoc1();
    });

    recentLink2.addEventListener('click', function(event){
        event.preventDefault();
        openRecentDoc2();
    });
});
