document.addEventListener('DOMContentLoaded', function(event) {

    var messagesLink1 = document.getElementById('messagesLink1'),
        messagesLink2 = document.getElementById('messagesLink2'),
        messagesIndex = document.getElementById('messagesIndex'),
        messagesDoc1 = document.getElementById('messagesDoc1'),
        messagesDoc2 = document.getElementById('messagesDoc2'),
        messagesUnread = document.getElementById('messagesUnread'),
        messagesUnreadCount = 2;

    function reduceUnreadCount(){
        // console.log(messagesUnreadCount);

        messagesUnreadCount = messagesUnreadCount - 1;

        if( messagesUnreadCount <= 0 ) {
            messagesUnread.classList.add('d-none');
        } else {
            messagesUnread.innerHTML = '(' + messagesUnreadCount + ')';
        }
    }

    function openMessagesIndex(){
        messagesIndex.classList.remove('d-none');
        messagesIndex.classList.add('d-flex');

        messagesDoc1.classList.add('d-none');
        messagesDoc1.classList.remove('d-flex');

        messagesDoc2.classList.add('d-none');
        messagesDoc2.classList.remove('d-flex');
    }

    function openMessagesDoc1(){
        messagesIndex.classList.add('d-none');
        messagesIndex.classList.remove('d-flex');

        messagesDoc1.classList.remove('d-none');
        messagesDoc1.classList.add('d-flex');

        messagesDoc2.classList.add('d-none');
        messagesDoc2.classList.remove('d-flex');

        reduceUnreadCount();
    }

    function openMessagesDoc2(){
        messagesIndex.classList.add('d-none');
        messagesIndex.classList.remove('d-flex');

        messagesDoc1.classList.add('d-none');
        messagesDoc1.classList.remove('d-flex');

        messagesDoc2.classList.remove('d-none');
        messagesDoc2.classList.add('d-flex');

        reduceUnreadCount();
    }

    messagesLink1.addEventListener('click', function(event){
        event.preventDefault();
        openMessagesDoc1();
    });

    messagesLink2.addEventListener('click', function(event){
        event.preventDefault();
        openMessagesDoc2();
    });
});
