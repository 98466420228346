var messages = document.getElementById('messages'),
    recent = document.getElementById('recent'),
    headerNavMessages = document.getElementById('headerNavMessages'),
    headerNavRecent = document.getElementById('headerNavRecent'),
    searchResultsWrapper = document.getElementById('searchResultsWrapper'),
    isPopupOpen = false,
    headerNav = document.getElementById('headerNav'),
    popup = document.getElementById('popup'),
    popupChat = document.getElementById('popupChat'),
    popupChatInput = document.getElementById('popupChatInput'),
    popupChatSubmit = document.getElementById('popupChatSubmit'),
    popupClose = document.getElementById('popupClose'),
    popupTimeout = 2000,
    chatMessageTimeout2 = 5000,
    chatMessageTimeout3 = 12000;

function openPopup(){

    if( isPopupOpen === false ) {
        isPopupOpen = true;
        popup.classList.remove('d-none');
        popup.classList.add('d-flex');
        document.body.style.overflow = 'hidden';
        popupChatInput.focus();
    }

    setTimeout(function(){
        var chatMessage = '<span class="d-block text-black">(45.87.212.214)</span>Find Victor\'s case files in the database. You\'ll need his specimen #. They may be the key to finding Victor.';
        popupChat.innerHTML += '<div class="text-gray-dark mt-1">' + chatMessage + '</div>';

        popupChat.scrollTop = popupChat.scrollHeight;
    }, chatMessageTimeout2);

    setTimeout(function(){
        var chatMessage = '<span class="d-block text-black">(45.87.212.214)</span> <span class="text-black">Has left the chat.</span>';
        popupChat.innerHTML += '<div class="text-gray-dark mt-1">' + chatMessage + '</div>';

        var closeLink = document.createElement('div');
        closeLink.className = 'text-gray-dark';
        closeLink.id = 'popupCloseLink';
        closeLink.innerHTML = '<span class="d-block text-blue cursor-pointer mt-4" onclick="closePopup()">Close messenger?</span>';
        popupChat.appendChild(closeLink);

        closeLink.addEventListener('click', function(event){
            event.preventDefault;
            closePopup();
        });

        popupChat.scrollTop = popupChat.scrollHeight;
    }, chatMessageTimeout3);
}

function closePopup(){

    if( isPopupOpen === true ) {
        isPopupOpen = false;
        popup.classList.remove('d-flex');
        popup.classList.add('d-none');
        document.body.style.overflow = 'auto';

    }
}
function textareaKeyPress(event) {

    var popupChatInput = document.getElementById('popupChatInput');

    if (event.keyCode === 13 && popupChatInput.value.length > 0) {
        popupChatSubmit.click();
    }
}

function openMessages(){
    messages.classList.remove('d-none');
    messages.classList.add('d-flex');

    recent.classList.remove('d-flex');
    recent.classList.add('d-none');

    headerNavMessages.classList.add('bg-gray-light', 'text-black');
    headerNavRecent.classList.remove('bg-gray-light', 'text-black');

    var messagesIndex = document.getElementById('messagesIndex'),
        messagesDoc1 = document.getElementById('messagesDoc1'),
        messagesDoc2 = document.getElementById('messagesDoc2');

    messagesIndex.classList.remove('d-none');
    messagesIndex.classList.add('d-flex');

    messagesDoc1.classList.add('d-none');
    messagesDoc1.classList.remove('d-flex');

    messagesDoc2.classList.add('d-none');
    messagesDoc2.classList.remove('d-flex');

    searchResultsWrapper.classList.add('d-none');
    searchResultsWrapper.classList.remove('d-block');
}

function openRecent(){
    messages.classList.remove('d-flex');
    messages.classList.add('d-none');

    recent.classList.remove('d-none');
    recent.classList.add('d-flex');

    headerNavMessages.classList.remove('bg-gray-light', 'text-black');
    headerNavRecent.classList.add('bg-gray-light', 'text-black');

    var recentIndex = document.getElementById('recentIndex'),
        recentDoc1 = document.getElementById('recentDoc1'),
        recentDoc2 = document.getElementById('recentDoc2');

    recentIndex.classList.remove('d-none');
    recentIndex.classList.add('d-flex');

    recentDoc1.classList.add('d-none');
    recentDoc1.classList.remove('d-flex');

    recentDoc2.classList.add('d-none');
    recentDoc2.classList.remove('d-flex');

    searchResultsWrapper.classList.add('d-none');
    searchResultsWrapper.classList.remove('d-block');
}


function closePopup(){
    document.getElementById('popup').classList.remove('d-flex');
    document.getElementById('popup').classList.add('d-none');
    document.body.style.overflow = 'auto';
}

document.addEventListener('DOMContentLoaded', function(event) {

    var submitChat = function(event){
        event.preventDefault();

        popupChat.innerHTML += '<div class="mt-1 text-right">' + popupChatInput.value + '</div>';

        popupChat.scrollTop = popupChat.scrollHeight;

        popupChatInput.focus();
        popupChatInput.value = '';
    };

    popupChatSubmit.addEventListener('click', submitChat);
});
